export const Permissions = {
  ADMIN: 'admin',
  DISPUTES: 'disputes',
  AIRS: 'airs',
  LIBRA: 'libra',
  RESEARCH: 'research',
  ORION: 'orion',
  DISPUTE_DELETE_ATTACHMENTS: 'dispute_delete_attachments',
  RESEARCH_DELETE_ATTACHMENTS: 'research_delete_attachments',
  DISPUTE_ARCHIVE: 'dispute_archive',
  RESEARCH_ARCHIVE: 'research_archive',
  PREVIEW_RESEARCH: 'preview_research',
  PREVIEW_DISPUTE: 'preview_dispute',
  EXPEDITE_SETTINGS: 'expedite_settings',
  ORION_SEARCH_TEMPLATES: 'orion_search_templates',
  ORION_HCR_COLUMN_TEMPLATES: 'orion_hcr_column_templates',
  ORION_DELETE_HOUSING_RECORD: 'orion_delete_housing_record',
  ORION_EDIT_RESEARCHER_ACCOUNT_SETTINGS: 'orion_edit_researcher_account_settings',
  ORION_CREATE_RESEARCHER_AND_USER: 'orion_create_researcher_and_user',
  ORION_EDIT_CLIENT_ACCOUNT_SETTINGS: 'orion_edit_client_account_settings',
  ORION_EXPORT_EDIT_QUEUE_INDEX_CSV: 'orion_export_edit_queue_index_csv',
  ORION_RESEARCHER: 'orion_researcher',
  ORION_UD: 'orion_ud',
  ORION_EDIT_QUEUE_EDIT_CERTAIN_FIELDS: 'orion_edit_queue_edit_certain_fields',
  ORION_EDIT_QUEUE_MODIFY_RESEARCHER: 'orion_edit_queue_modify_researcher',
  ORION_EDIT_QUEUE_REVIEW: 'orion_review_edit_queue',
  ORION_HCR_UPLOAD_NJ_DISPOSITION: 'orion_hcr_upload_nj_disposition',
  ORION_HCR_UPLOAD_ACTIONS: 'orion_hcr_upload_actions',
  ORION_CREATE_ADDITIONAL_PAYMENTS: 'orion_create_additional_payments',
  ORION_ALL_RESEARCHER_DOCUMENTS: 'orion_all_researcher_documents',
  ORION_DELETE_RESEARCHER_DOCUMENTS: 'orion_delete_researcher_documents',
  ORION_EDIT_QUEUE_ERROR_SUMMARY: 'orion_edit_queue_error_summary',
  CENTRAL_ARCHIVE_EMAIL: 'central_archive_email',
  CENTRAL_VIEW_EMAILS: 'central_view_emails',

};

export const DashboardPermissions = [
  Permissions.ADMIN,
  Permissions.DISPUTES,
  Permissions.RESEARCH,
];
